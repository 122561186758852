.SignIn {
    width: 20vw;
    font-size: var(--font-size-1);
    display: flex;
    justify-content: end;
}

.SignIn .Button {
    cursor: pointer;
    font-weight: var(--font-weight-6)
}

.SignIn .Button:hover {
    color: var(--gray-7);
}